import * as React from "react"
import styled from 'styled-components'

const Header = ({ title, image, subtitle }) => {
  return (
    <TitleContainer image={image}>
      <BackgroundCover/>
      <Title withSubtitle={subtitle}>
        <h1>{title}</h1>
        <Subtitle>{subtitle}</Subtitle>
      </Title>
    </TitleContainer>
  )
}

export default Header

const Title = styled.div`
  font-weight: 100;
  text-align: center;
  color: white;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (max-width: 500px) {
    top: ${props => props.withSubtitle == null ? "35%" : "20%"};
  }
`

const Subtitle = styled.h3`
  font-weight: 100;
`

const TitleContainer = styled.div`
  background-image: url('${props => props.image}');
  width: 100%;
  paddingTop: 100px;
  background-size: cover;
  position: relative;

  @media only screen and (max-width: 599px) {
    background-position-x: -250px;
  }

  @media only screen and (max-width: 400px) {
    background-position-x: -350px;
  }
`

const TitleContainerOld = styled.div`
  background-image: url('${props => props.image}');
  width: 100%;
  paddingTop: 100px;
  background-size: cover;
  position: relative;

  @media only screen and (max-width: 599px) {
    background-position-x: -200px;
  }

  @media only screen and (max-width: 400px) {
    background-position-x: -350px;
  }
`

const BackgroundCover = styled.div`
  background-color: #000353;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  padding-top: 150px;
  padding-bottom: 150px;
`
