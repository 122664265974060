import * as React from "react"
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components'
import Logo from '../images/logos/logo_vertical_blanco.png'

const Footer = () => {
  return (
    <Grid direction="row" container style={{ paddingTop: "75px", backgroundColor: "#000353", color: "white" }}>
      <Grid item container xs={12} sm={4} justify="center" alignItems="center">
    	  <LogoComponent src={Logo} alt=''/>
      </Grid>
      <Grid direction="column" item container xs={12} sm={8} justify="center" alignItems="center">
      	<Grid item>
        	<Linked href='tel:229065720'><Icon src={require('../images/icons/phoneWhite.png')} alt=''/>229065720</Linked>
      	</Grid>
        <Grid item style={{ marginTop: "20px"}}>
        	<Linked href='tel:+56967283885'><Icon src={require('../images/icons/phoneWhite.png')} alt=''/>+56 9 67283885</Linked>
      	</Grid>
      	<Grid item style={{ marginTop: "20px"}}>
            <Linked href='mailto:contacto@laboratoriosanmartin.cl'><Icon src={require('../images/icons/mailWhite.png')} alt=''/>contacto@laboratoriosanmartin.cl</Linked>
      	</Grid>
      	<Grid item style={{ marginTop: "20px"}}>
            <NonLinked><Icon src={require('../images/icons/waypoint.png')} alt=''/>San Martín 731 (ex 241), San Felipe</NonLinked>
      	</Grid>
        <Grid item style={{ marginTop: "20px"}}>
          <a href="https://twitter.com/labosanmartin"><Icon src={require('../images/icons/twitterWhite.png')} alt='' style={{width: "40px", height:"40px"}}/></a>
          <a href="https://www.instagram.com/lcsmsf"><Icon src={require('../images/icons/instaWhite.png')} alt='' style={{width: "40px", height:"40px"}}/></a>
          <a href="https://facebook.com/LCSM.SanFelipe"><Icon src={require('../images/icons/faceWhite.png')} alt='' style={{width: "40px", height:"40px", borderRadius:"40px" }}/></a>
      	</Grid>
      </Grid>
      <a style={{ color: "white", opacity: "0.4", fontSize: 14, width:"100%", textAlign: "center", paddingBottom: "15px", paddingTop: "45px"}} href="https://www.freepik.es/" title="bacteria icons">Icons created by Freepik</a>
    </Grid>
  )
}

const LogoComponent = styled.img`
  width: 150px;
  object-fit: contain;

   @media only screen and (max-width: 599px) {
      margin-bottom: 40px;
  }
`

const Linked = styled.a`
  color: white;

  &: hover {
    color: #f2790f;
  }
`

const NonLinked = styled.div`
  color: white;
`


const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`

export default Footer
