import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components'
import { Link } from "gatsby"
import Logo from '../images/logos/logo_normal.png'
import Hamburger from '../images/icons/hamburger-black.png'
import { useLocation } from '@reach/router';
import { Alert } from '@material-ui/lab'
import { Collapse } from '@material-ui/core';


const Navbar = () => {

	const [modal, setModal] = useState(false)
	const location = useLocation()
	const [open, setOpen] = useState(false)

	const html = typeof document !== `undefined` ? document.querySelector('html') : null
	let windowGlobal = typeof window !== `undefined` ? window : null

	if (windowGlobal !== null) {
		windowGlobal.addEventListener('resize', () => {
			if (windowGlobal.innerWidth >= 694 && modal) {
				setModal(false)
			}
		})
	}

	if (open) {
    setTimeout(() => { setOpen(false) }, 3000)
  }

  useEffect(() => {
    modal ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible')
  }, [modal])

  return (
    <Container>
    	<NormalGrid direction="row" container style={{ marginTop: "15px", fontSize: "15px" }}>
	        <Grid container item xs={9} sm={2} justify="flex-start" alignItems="center" style={{ minWidth: "210px", minHeight: "80px", paddingLeft: "3vw" }}>
	        	<Link to="/">
	        		<LogoComponent src={Logo}/>
	       		</Link>
	        </Grid>
	         <Grid container item xs={1} md={2}>
	        </Grid>
	        <Grid item style={{ marginRight: "5vw", textAlign: "center", width: "auto" }} container alignItems="center">
	        	<Link to="/" style={{ textDecoration: 'none' }}>
	        		<NavButton active={location.pathname === "/" ? true : false}>Inicio</NavButton>
	        	</Link>
	        </Grid>
	        <Grid item style={{ marginRight: "5vw", textAlign: "center", width: "auto" }} container alignItems="center">
	        	<Link to="/tecnologia" style={{ textDecoration: 'none' }}>
	        		<NavButton active={location.pathname === "/tecnologia" || location.pathname === "/tecnologia/" ? true : false}>Tecnología</NavButton>
	        	</Link>
	        </Grid>
	        <Grid item style={{ marginRight: "5vw", textAlign: "center", width: "auto" }} container alignItems="center">
	        	<Link to="/nosotros" style={{ textDecoration: 'none' }}>
	        		<NavButton active={location.pathname === "/nosotros" || location.pathname === "/nosotros/" ? true : false}>Nosotros</NavButton>
	        	</Link>
	        </Grid>
	        <Grid item style={{ marginRight: "2vw", textAlign: "center", width: "auto" }} container alignItems="center">
	        	<Link to="/contacto" style={{ textDecoration: 'none' }}>
	        		<NavButton active={location.pathname === "/contacto" || location.pathname === "/contacto/" ? true : false}>Contacto</NavButton>
	        	</Link>
	        </Grid>
					<div style={{position: "absolute", top: 110, right: 10}}>
						<a href="http://lcsmresultados.ddns.net" style={{ textDecoration:"none", cursor: "pointer" }}>
							<div style={{ display: "flex", marginBottom: "10px", color: "white", borderRadius: "10px", width: "110px", textAlign: "center", backgroundColor: "#f2790f", padding: "2px"}}>
								<img src={require('../images/icons/docWhite.png')} alt='' style={{ paddingLeft: "4px", marginTop:"8px", width: "18px", height:"18px" }}/>
								<div>Resultado Exámenes</div>
							</div>
						</a>
					</div>
	    </NormalGrid>
	    <ResponsiveGrid direction="row" container style={{ marginTop: "15px", fontSize: "15px" }}>
	    	<Grid container item xs={9} justify="flex-start" alignItems="center" style={{ minWidth: "210px", paddingLeft: "3vw" }}>
        	<Link to="/">
        		<LogoComponent src={Logo}/>
       		</Link>
        </Grid>
				<div style={{position: "absolute", top: 110, right: 10}}>
					<a href="http://lcsmresultados.ddns.net" style={{ textDecoration:"none", cursor: "pointer" }}>
						<div style={{ display: "flex", marginBottom: "10px", color: "white", borderRadius: "10px", width: "110px", textAlign: "center", backgroundColor: "#f2790f", padding: "2px"}}>
							<img src={require('../images/icons/docWhite.png')} alt='' style={{ paddingLeft: "4px", marginTop:"8px", width: "18px", height:"18px" }}/>
							<div>Resultado Exámenes</div>
						</div>
					</a>
				</div>
	    	<Grid xs={3} item style={{ width: "auto", marginBottom: "10px" }} container alignItems="center" justify="flex-end">
				<div style={{ zIndex: 2 }} onClick={() => setModal(!modal)}><Icon src={Hamburger} alt='menu' style={{ cursor: "pointer" }}/></div>
				{modal ? <ModalNavbar modal={modal} setModal={setModal} width="80%" opacity="0.7" disabled={"auto"} xaxis="0"/> : <ModalNavbar modal={modal} setModal={setModal} width="0" hidden={true} opacity="0" disabled={"none"} xaxis="100px"/>}
	        </Grid>
	    </ResponsiveGrid>
			{open ?
        <AlertContainer>
          <Collapse in={open}>
            <Alert severity="info">Resultados online actualmente en desarrollo</Alert>
          </Collapse>
        </AlertContainer>
       : ""}
    </Container>
  )
}

const ModalNavbar = ({ modal, setModal, width, opacity, disabled, xaxis }) => {
	const location = useLocation()

	return (
		<ModalContainer>
			<div onClick={() => setModal(!modal)} style={{ transition: "0.5s", pointerEvents: disabled, backgroundColor: "black", opacity: opacity, position: "fixed", height: "150%", left: 0, top: 0, width: "100%", zIndex: 1}}/>
			<div style={{ transition: "0.5s", backgroundColor: "white", opacity: "0.95", position: "fixed", height: "100%", right: 0, top: 0, width: width, zIndex: 1, borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px"}}>
				<Grid container spacing={4} direction="column" alignItems="flex-start" style={{ paddingLeft: "50px", paddingRight: "50px", paddingTop: "100px", height: "100vh"}}>
					<Grid item style={{ marginRight: "5vw", textAlign: "center", width: "auto" }} container alignItems="center">
		        	<Link to="/" style={{ textDecoration: 'none' }}>
        				<NavButton active={location.pathname === "/" ? true : false}>Inicio</NavButton>
		        	</Link>
		        </Grid>
		        <Grid item style={{ marginRight: "5vw", textAlign: "center", width: "auto" }} container alignItems="center">
		        	<Link to="/tecnologia" style={{ textDecoration: 'none' }}>
        				<NavButton active={location.pathname === "/tecnologia" || location.pathname === "/tecnologia/" ? true : false}>Tecnología</NavButton>
		        	</Link>
		        </Grid>
		        <Grid item style={{ marginRight: "5vw", textAlign: "center", width: "auto" }} container alignItems="center">
		        	<Link to="/nosotros" style={{ textDecoration: 'none' }}>
        				<NavButton active={location.pathname === "/nosotros" || location.pathname === "/nosotros/" ? true : false}>Nosotros</NavButton>
		        	</Link>
		        </Grid>
		        <Grid item style={{ marginRight: "2vw", textAlign: "center", width: "auto" }} container alignItems="center">
		        	<Link to="/contacto" style={{ textDecoration: 'none' }}>
        				<NavButton active={location.pathname === "/contacto" || location.pathname === "/contacto/" ? true : false}>Contacto</NavButton>
		        	</Link>
		        </Grid>
		        <LogoGrid item justify="center" style={{ transition: "0.5s", marginTop: "50px", marginLeft: xaxis }} container >
		        	<LogoComponent src={Logo} alt=''/>
		        </LogoGrid>
				</Grid>
			</div>
		</ModalContainer>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0;
	width: 100%;
	background-color: white;
	box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
`

const LogoComponent = styled.img`
	width: 200px;
	max-height: 100px;
	object-fit: contain;
`

const NavButton = styled.div`
	text-decoration: none;
	color: ${props => props.active ? "white" : "#000353"};
	${props => props.active ?
		"background-color: #000353; border-radius: 20px;" : ""}
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
`

const Icon = styled.img`
	width: 32px;
	height: 32px;
	margin-right: 20px;

	@media only screen and (min-width: 600px) {
    	position: absolute;
    	right: 0;
    	top: 30px;
  	}
`

const LogoGrid = styled(Grid)`
	position: absolute;
	bottom: 50px;
	left: 0;
	right: 0;

	@media only screen and (max-height: 492px) {
		display: none;
	}
`

const ModalContainer = styled.div`
	overflow: hidden;
`

const NormalGrid = styled(Grid)`
	@media only screen and (max-width: 693px) {
		display: none;
	}
`

const ResponsiveGrid = styled(Grid)`
	text-align: right;

	@media only screen and (min-width: 694px) {
    	display: none;
  	}
`

const AlertContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 93px;
  background-color: white;
`

export default Navbar
