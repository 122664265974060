import * as React from "react"
import {Helmet} from 'react-helmet'
import favicon from '../images/icon.png'

// markup
const SEO = ({ title, description }) => {
  return (
    <Helmet htmlAttributes={{"lang": "es"}}>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta charSet="UTF-8"/>
        <link rel="shortcut icon" href={favicon} />
        <link
		  rel="stylesheet"
		  type="text/css"
		  charset="UTF-8"
		  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
		/>
		<link
		  rel="stylesheet"
		  type="text/css"
		  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
		/>
    </Helmet>
  )
}

export default SEO
